import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import constants from './constants'

const { formattedDate } = utils

const { SHIPMENT_TEXT, SHIPMENT_STATUS, ORDER_STATUS } = c

const { ORDER_PRODUCT_SHIPMENT_STATUS } = constants

export const setFilter = (filter, condition, newFilter) => ((condition && (condition.length === 0)) ? newFilter : filter)

export const parsePaymentStatus = status => {
  if (status === 'Overdue') return 'danger'
  if (status === 'Paid') return 'success'
  if (status === 'Due') return 'warning'
}

export const dataToCurrency = data => Number(data).toFixed(2)

export const getLinkWithoutQueryParams = link => link.split('?')[0]

export const dataURLtoFile = (dataurl, filename) => {
  if (!dataurl || !filename) {
    return null
  }
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length > 1) return parts.pop().split(';').shift()
  return null
}

export const setCookies = (cookieName, expiry) => {
  document.cookie = `${cookieName}=${new Date().getTime()}; expires=${expiryTime(expiry)};`
  document.cookie = `${cookieName}=${new Date().getTime()}; domain=kingpin.global; expires=${expiryTime(expiry)};`
}

const expiryTime = days => new Date(new Date().getTime() + (1000 * 3600 * 24 * days)).toUTCString()

export const getDateFromTimestamp = (timestamp, isReverse = false) => {
  if (!timestamp) {
    return '--/--/----'
  }
  const day = new Date(timestamp)
  let dd = day.getDate()
  let mm = day.getMonth() + 1

  const yyyy = day.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  if (isReverse) {
    return `${yyyy}/${mm}/${dd}`
  }
  return `${dd}/${mm}/${yyyy}`
}

// Parameters - array of ojects, start date and end date
export const filterDataByDate = (arrayData, start, end) => {
  if (!start || !end) {
    return arrayData
  }
  const startDate = getDateFromTimestamp(start, true)
  const endDate = getDateFromTimestamp(end, true)

  const filteredArray = arrayData.filter(data => {
    const createdAt = getDateFromTimestamp(data.createdAt, true)
    return createdAt >= startDate && createdAt <= endDate
  })
  return filteredArray
}

// It returns {'status message', 'color', 'boolean'}
export const getStatusTextAndColorCode = (status, order = null) => {
  const statusContent = {
    text: '',
    colorCode: '',
    isEnabled: false,
  }
  if (!status) {
    return statusContent
  }
  // Status for after shipment created
  const isStatusInShipment = Object.keys(SHIPMENT_TEXT).includes(status)
  const isOrderStatusCancelled = (order?.status === ORDER_STATUS.CANCELLED)
  const isProductStatusCanceled = (status === ORDER_STATUS.CANCELLED)
  if (isStatusInShipment && !isOrderStatusCancelled && !isProductStatusCanceled) {
      statusContent.text = status === SHIPMENT_STATUS.SHIPMENT_CREATED ? ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.TEXT : SHIPMENT_TEXT[status]
      statusContent.colorCode = constants.ORDER_PRODUCT_STATUS_COLOR_CODE[ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.LABEL]
      return statusContent
  }
  const text = Object.values(ORDER_PRODUCT_SHIPMENT_STATUS).find(_ => _.LABEL === status).TEXT
  statusContent.text = text
  statusContent.colorCode = constants.ORDER_PRODUCT_STATUS_COLOR_CODE[status]
  statusContent.isEnabled = [ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.LABEL, ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL].includes(status)
  return statusContent
}

export const getProperProfileData = data => ({
  _id: data.id,
  general: {
    logo: data.logo,
    banner: data.banner,
    entityName: data.entityName,
    firstName: data.firstName,
    lastName: data.lastName,
    country: data.country,
    phone: data.phone,
    countryCode: data.countryCode,
    storeDescription: data.storeDescription,
    email: data.email,
    storeType: data.storeType || null,
    role: data.role,
  },
  shippingAddress: {
    addressLine1: data.shippingAddressLine1 || '',
    addressLine2: data.shippingAddressLine2 || '',
    city: data.shippingCity || '',
    country: data.shippingCountry || '',
    contactName: data.shippingContactName || '',
    contactNumber: data.shippingContactNumber || '',
    contactEmail: data.shippingContactEmail || '',
    zipcode: data.shippingZipcode || '',
  },
  billingAddress: {
    addressLine1: data.billingAddressLine1 || '',
    addressLine2: data.billingAddressLine2 || '',
    city: data.billingCity || '',
    country: data.billingCountry || '',
    contactName: data.billingContactName || '',
    contactNumber: data.billingContactNumber || '',
    contactEmail: data.billingContactEmail || '',
    zipcode: data.billingZipcode || '',
  },
  social: {
    websiteLink: data.websiteLink,
    twitterLink: data.twitterLink,
    facebookLink: data.facebookLink,
    instagramLink: data.instagramLink,
    linkedinLink: data.linkedinLink,
  },
  documents: {
    companyName: data.companyName,
    // used split function for remove unwanted queries in the certificate urls
    tradeLicense: data.tradeLicense != null ? data.tradeLicense.split('?')[0] : null,
    vatCertificate: data.vatCertificate != null ? data.vatCertificate.split('?')[0] : null,
    tradeLicenseNumber: data.tradeLicenseNumber,
    vatCertificateNumber: data.vatCertificateNumber,
    companyProfile: data.companyProfile != null ? data.companyProfile.split('?')[0] : null,
    others: data.others != null ? data.others.split('?')[0] : null,
    tradeLicenseExpiryDate: data.tradeLicenseExpiryDate ? formattedDate(data.tradeLicenseExpiryDate) : null,
    dateOfIncorporation: data.dateOfIncorporation ? formattedDate(data.dateOfIncorporation) : null,
  },
  segmentation: {
    departments: data.departments,
    restrictedCountries: data.restrictedCountries,
    avgRetailPrice: data.avgRetailPrice,
    avgWholesalePrice: data.avgWholesalePrice,
    minimumOrderValue: data.minimumOrderValue,
  },
})

export const getEntityPayloadForGeneralSection = (userGeneralData, entityData) => {
  const payload = {
    ...entityData,
    ...userGeneralData,
  }
  delete payload.firstName
  delete payload.lastName
  delete payload.id
  delete payload.role
  delete payload.email

  return payload
}

export const getUpdatedAddresses = addresses => {
  const updatedAddresses = []

  addresses.forEach(address => {
    const newAddress = { ...address }

    delete newAddress.isSaving
    delete newAddress.isEditEnabled

    updatedAddresses.push(newAddress)
  })

  return updatedAddresses
}

export const convertToHtml = message => {
  let htmlMessage = message.replace('.', '.<br/>')
  htmlMessage = htmlMessage.replace('"', '<strong class=\'color-blue\'>')
  htmlMessage = htmlMessage.replace('"', '</strong>')
  return htmlMessage
}

export function formattedString(str) {
  if (!str) return ''
  let i; const
    frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase()
  }
  return frags.join(' ')
}

export function objectFlip(obj, valueTransformFunc) {
  const ret = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (valueTransformFunc) {
        ret[obj[key]] = valueTransformFunc(key)
      } else {
        ret[obj[key]] = key
      }
    }
  }
  return ret
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const getLinkWithhttp = link => {
  if (!link) {
    return
  }
  if (link.includes('https://')) {
    return link
  }
  return `https://${link}`
}

export const replaceURLTextWithHyperLink = text => {
  const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i
  return text?.replace(exp, "<br><a class='text-info text-decoration-underline' target='_blank' href='$1'>$1</a><br>")
}

export const getFromattedTimeFromDate = timestamp => {
  const utcDate = new Date(timestamp)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: userTimeZone,
}
 return new Intl.DateTimeFormat(undefined, options).format(utcDate)
}

export const sortByUpdatedAt = (array = []) => array?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

export const convertDateStringToDateObj = dateStr => {
  const dateParts = dateStr.split('/')

  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
}
